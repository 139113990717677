// Utilities
import { db, FieldValue, Timestamp } from "@/firebase";

// Types
import { Entry } from "@typings/entry";
import {
  DocumentData,
  FirestoreDataConverter,
  QueryDocumentSnapshot,
  SnapshotOptions,
} from "@typings/firebase";

const entryConverter = {
  toFirestore: (entry: Entry): DocumentData => {
    return {
      id: entry.id,
      createdAt: Timestamp.fromDate(entry.createdAt),
      date: Timestamp.fromDate(entry.date),
      highlight: entry.highlight,
      mood: entry.mood,
      text: entry.text,
      updatedAt: entry.updatedAt ? Timestamp.fromDate(entry.updatedAt) : null,
      userId: entry.userId,
    };
  },
  fromFirestore: (
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions,
  ): Entry => {
    const data = snapshot.data(options);
    return {
      id: snapshot.id,
      createdAt: data.createdAt.toDate(),
      date: data.date.toDate(),
      highlight: data.highlight ?? false,
      mood: data.mood ?? null,
      text: data.text,
      updatedAt: data.updatedAt?.toDate() ?? null,
      userId: data.userId,
    };
  },
};

abstract class EntryService {
  static converter: FirestoreDataConverter = entryConverter;

  /**
   * Add a journal entry
   *
   * @param   entry Journal entry to add
   * @returns New journal entry
   */
  static async addEntry(entry: Entry): Promise<Entry> {
    const entryRef = db
      .collection("entries")
      .withConverter(entryConverter)
      .doc(entry.id);
    const userRef = db.collection("users").doc(entry.userId);

    await db.runTransaction(async (transaction) => {
      await transaction.set(entryRef, entry);
      await transaction.update(userRef, { entries: FieldValue.increment(1) });
    });

    return entry;
  }

  /**
   * Delete a journal entry
   *
   * @param   entry Journal entry to delete
   * @returns Deleted journal entry
   */
  static async deleteEntry(entry: Entry): Promise<Entry> {
    const entryRef = db
      .collection("entries")
      .withConverter(entryConverter)
      .doc(entry.id);
    const userRef = db.collection("users").doc(entry.userId);

    await db.runTransaction(async (transaction) => {
      await transaction.delete(entryRef);
      await transaction.update(userRef, { entries: FieldValue.increment(-1) });
    });

    return entry;
  }

  /**
   * Get journal entries
   * @param   userId User ID
   *
   * @returns Journal entries
   */
  static async getEntries(userId: string): Promise<Entry[]> {
    const entriesRef = await db
      .collection("entries")
      .withConverter(entryConverter)
      .where("userId", "==", userId)
      .orderBy("date", "desc")
      .limit(50)
      .get();

    const entries: Entry[] = [];
    entriesRef.forEach((e) => {
      const entry = e.data();
      entries.push(entry);
    });

    return entries;
  }

  /**
   * Update a journal entry
   *
   * @param   entry Journal entry to update
   * @returns Updated journal entry
   */
  static async updateEntry(entry: Entry): Promise<Entry> {
    const entryRef = db
      .collection("entries")
      .withConverter(entryConverter)
      .doc(entry.id);

    // Ensure that the date updated has been set
    entry.updatedAt = new Date();

    await db.runTransaction(async (transaction) => {
      await transaction.update(entryRef, entry);
    });

    return entry;
  }
}

export default EntryService;
