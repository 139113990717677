enum Errors {
  /** User cancelled authentication */
  AUTH__CANCELLED = "AUTH__CANCELLED",
  /** User is not authenticated */
  AUTH__NO_AUTH = "AUTH__NO_AUTH",
  /** No user profile */
  AUTH__NO_PROFILE = "AUTH__NO_PROFILE",
  /** No user account */
  AUTH__NO_USER = "AUTH__NO_USER",
  /** Adding journal entry failed */
  ENTRY__ADD_FAILED = "ENTRY__ADD_FAILED",
  /** Deleting journal entry failed */
  ENTRY__DELETE_FAILED = "ENTRY__DELETE_FAILED",
  /** Saving journal entry failed (generic) */
  ENTRY__SAVE_FAILED = "ENTRY__SAVE_FAILED",
  /** Updating journal entry failed */
  ENTRY__UPDATE_FAILED = "ENTRY__UPDATE_FAILED",
}

const ErrorMap = {
  [Errors.AUTH__NO_PROFILE]: "No profile found for user",
  [Errors.AUTH__NO_USER]: "No Google account found",
  [Errors.ENTRY__ADD_FAILED]: "Failed to create entry",
  [Errors.ENTRY__DELETE_FAILED]: "Failed to remove entry",
  [Errors.ENTRY__SAVE_FAILED]: "Failed to save entry",
  [Errors.ENTRY__UPDATE_FAILED]: "Failed to update entry",
};

export { Errors, ErrorMap };
