// Utilities
import { Errors } from "@/errors";
import { db, Timestamp } from "@/firebase";
import {
  DocumentData,
  FirebaseUser,
  QueryDocumentSnapshot,
  SnapshotOptions,
} from "@typings/firebase";
import { User, UserProfile } from "@typings/user";

const profileConverter = {
  toFirestore: (profile: UserProfile): DocumentData => {
    return {
      activatedAt: profile.activatedAt
        ? Timestamp.fromDate(profile.activatedAt)
        : null,
      entries: profile.entries,
    };
  },
  fromFirestore: (
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions,
  ): UserProfile => {
    const data = snapshot.data(options);
    return {
      activatedAt: data.activatedAt?.toDate(),
      entries: data.entries,
    };
  },
};

abstract class UserService {
  /**
   * Get a Firebase user profile
   *
   * @param   userUid Firebase user UID
   * @returns User profile
   * @throws  {AUTH__NO_PROFILE} No profile for user
   */
  static getProfile = async (userUid: string): Promise<UserProfile> => {
    const userRef = await db
      .collection("users")
      .withConverter(profileConverter)
      .doc(userUid)
      .get();

    if (!userRef.exists) throw new Error(Errors.AUTH__NO_PROFILE);

    return userRef.data() as UserProfile;
  };

  /**
   * Get an app user (with profile information)
   *
   * @param   user Firebase user (JWT)
   * @returns App user (with profile)
   * @throws  {AUTH__NO_PROFILE} No profile for user
   */
  static getUser = async (user: FirebaseUser): Promise<User> => {
    const profile = await UserService.getProfile(user.uid);

    return {
      // Firebase credential information
      displayName: user.displayName || "N/A",
      email: user.email || "N/A",
      photoUrl: user.photoURL,
      phoneNumber: user.phoneNumber,
      uid: user.uid,
      // Firestore profile information
      activatedAt: profile.activatedAt,
      entries: profile.entries,
    };
  };
}

export default UserService;
