import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

// Utilities
import config from "@/config";
import { Errors } from "@/errors";
import { FirebaseUser } from "@typings/firebase";

// TODO: Replace with configuration object to support multiple environments
/** Firebase application */
const firebaseConfig = config.firebase;
const firebaseApp = firebase.initializeApp({
  apiKey: firebaseConfig.apiKey,
  appId: firebaseConfig.appId,
  authDomain: firebaseConfig.authDomain,
  projectId: firebaseConfig.projectId,
  storageBucket: firebaseConfig.storageBucket,
  messagingSenderId: firebaseConfig.messagingSenderId,
});

/** Firebase authentication */
const auth = firebaseApp.auth();
/** Firestore database */
const db = firebaseApp.firestore();
/** Google auth provider */
const googleAuth = new firebase.auth.GoogleAuthProvider();

/**
 * Login to Google
 *
 * @returns Google user
 * @throws  {AUTH__NO_USER}        User could not authenticate
 * @throws  {AUTH__USER_CANCELLED} User cancelled authentication
 */
const loginGoogle = async (): Promise<FirebaseUser> =>
  firebase
    .auth()
    .signInWithPopup(googleAuth)
    .then((result) => {
      if (!result.user) throw new Error(Errors.AUTH__NO_USER);
      return result.user;
    })
    .catch((e) => {
      // Closing the Google login modal should indicate user cancelled authentication
      const ignoreErrors = [
        "auth/cancelled-popup-request",
        "auth/popup-closed-by-user",
      ];
      if (ignoreErrors.includes(e.code))
        throw new Error(Errors.AUTH__CANCELLED);

      throw e;
    });

/**
 * Logout from Google
 */
const logoutGoogle = (): Promise<void> => firebase.auth().signOut();

/** Firebase timestamp */
const Timestamp = firebase.firestore.Timestamp;
/** Firestore field value */
const FieldValue = firebase.firestore.FieldValue;

export { auth, db, FieldValue, loginGoogle, logoutGoogle, Timestamp };
