














import { Component, Emit, Prop, Vue } from "vue-property-decorator";

// Components
import ConfirmDialog from "./ConfirmDialog.vue";

@Component({
  components: {
    ConfirmDialog,
  },
})
export default class FormLeaveDialog extends Vue {
  /** Whether dialog is shown */
  @Prop({ default: false })
  value!: boolean;

  /**
   * Decide whether form can be left or not
   * @param   leaving - Whether form can be left
   * @returns Whether form should be left
   */
  @Emit("leave-form")
  close(leaving: boolean): boolean {
    return leaving;
  }
}
