










































































import { Component, Mixins } from "vue-property-decorator";
import {
  mdiAccount,
  mdiGoogle,
  mdiLogoutVariant as mdiLogout,
  mdiChartTimelineVariant as mdiTimeline,
} from "@mdi/js";

// Utilities
import config from "@/config";
import { AuthMixin } from "@mixins";

@Component
export default class TheAppHeader extends Mixins(AuthMixin) {
  icons = { mdiAccount, mdiGoogle, mdiLogout, mdiTimeline };
  version = config.version;

  /** Whether user is on logout route */
  get isLogoutRoute(): boolean {
    return this.$route.name === "logout";
  }

  /** Whether user is on home route */
  get isHomeRoute(): boolean {
    return this.$route.name === "home" && !this.authenticated;
  }
}
