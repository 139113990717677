import { VueConstructor } from "vue/types/vue";

// Components
import { ProgressIndicator } from "@components";
import { ConfirmDialog, FormLeaveDialog } from "@components/dialogs";
import { ActionBar } from "@components/layout";

// NOTE: Need to manually register components names because Terser
//         mangles class names by default...

const ComponentsPlugin = {
  install: (Vue: VueConstructor): void => {
    // Dialogs
    Vue.component("ConfirmDialog", ConfirmDialog);
    Vue.component("FormLeaveDialog", FormLeaveDialog);

    // Layout
    Vue.component("ActionBar", ActionBar);

    Vue.component("ProgressIndicator", ProgressIndicator);
  },
};

export default ComponentsPlugin;
