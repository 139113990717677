import { RouteConfig } from "vue-router";

// NOTE: Apparently all routes need to be lazily loaded to avoid problems
//         with using the 'AuthMixin' in view files, as it will be undefined
//         due to some strange depedency issue...

type VueFile = Promise<typeof import("*.vue")>;

const routes: Array<RouteConfig> = [
  // Unauthenticated routes
  {
    path: "/",
    name: "home",
    component: (): VueFile =>
      import(/* webpackChunkName: "main" */ "@views/Home"),
  },
  {
    path: "/logout",
    name: "logout",
    component: (): VueFile =>
      import(/* webpackChunkName: "logout" */ "@views/Auth/Logout.vue"),
  },
  // Authenticated routes
  {
    path: "/timeline",
    name: "timeline",
    component: (): VueFile =>
      import(/* webpackChunkName: "timeline" */ "@views/Timeline/Timeline.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/profile",
    name: "profile",
    component: (): VueFile =>
      import(/* webpackChunkName: "profile" */ "@views/User/Profile.vue"),
    meta: {
      requiresAuth: true,
    },
  },
];

export default routes;
