


































import { Component, Emit, Prop, Vue } from "vue-property-decorator";

@Component
export default class ConfirmDialog extends Vue {
  /** Cancel button text */
  @Prop({ default: "No " })
  cancelText!: string;
  /** Confirm button text */
  @Prop({ default: "Yes " })
  confirmText!: string;
  /** Whether the confirmation dialog is disabled */
  @Prop({ default: false })
  disabled!: boolean;
  /** Whether the confirmation dialog is loading */
  @Prop({ default: false })
  loading!: boolean;
  /** Dialog title */
  @Prop({ required: true })
  title!: string;
  /** Dialog width */
  @Prop({ default: 350 })
  width!: number;
  /** Whether the dialog is shown */
  @Prop({ required: true })
  value!: boolean;

  /** Cancel handler */
  @Emit("input")
  @Emit("cancel")
  cancel(): boolean {
    return false;
  }

  /**
   * Confirm handler
   */
  @Emit("input")
  @Emit("confirm")
  confirm(): boolean {
    return false;
  }
}
