





































import { Component, Vue } from "vue-property-decorator";

// Types
import { Snackbar } from "@typings/snackbar";

// Utilities
import { snackbarStore } from "@plugins/snackbar";

// Default snackbar duration
const SNACKBAR_DURATION = 4000;

@Component
export default class TheAppSnackbar extends Vue {
  get hasButton(): boolean {
    const { buttonIcon, buttonText, onClick } = this.snackbar;
    return Boolean((buttonIcon || buttonText) && onClick);
  }

  get snackbar(): Snackbar {
    const {
      buttonIcon = null,
      buttonText = null,
      closeable = false,
      onClick,
      permanent = false,
      text = "",
      timeout = SNACKBAR_DURATION,
      type = null,
      visible,
    } = snackbarStore;

    return {
      buttonIcon,
      buttonText,
      // Snackbars are always closeable if permanent
      closeable: permanent || closeable,
      text,
      timeout: permanent ? -1 : timeout,
      type,
      visible,
      onClick: onClick
        ? (): void => {
            onClick();
            this.close();
          }
        : undefined,
    };
  }

  close(): void {
    this.$notifyClose();
  }
}
