

































import { Component, Vue } from "vue-property-decorator";
import { mdiClose } from "@mdi/js";

// Utilities
import config from "@/config";
import { StorageService } from "@services";

@Component
export default class TheDebugHelper extends Vue {
  config = config;
  isBreakpointVisible = false;
  isDebugDialogShown = false;

  icons = { mdiClose };

  get isBreakpointVisibleInEnv(): boolean {
    return !config.production && this.isBreakpointVisible;
  }

  created(): void {
    window.addEventListener("beforeunload", this.onUnload);
  }

  mounted(): void {
    this.isBreakpointVisible = StorageService.getMediaQueryVisibility();
  }

  /**
   * Shortkey handler
   *
   * @param event Triggered shortkey event
   */
  onDebugKey(event: any): void {
    switch (event.srcKey) {
      case "debug":
        this.isDebugDialogShown = !this.isDebugDialogShown;
        break;
      case "media":
        this.isBreakpointVisible = !this.isBreakpointVisible;
        break;
      default:
        break;
    }
  }

  /**
   * Store media query visibility when closing window
   */
  onUnload(): void {
    StorageService.setMediaQueryVisibility(this.isBreakpointVisible);
  }
}
