












import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class ActionBar extends Vue {
  /** Whether actions are left-aligned */
  @Prop({ default: false })
  left!: boolean;
}
