// Utilities
import { version } from "../package.json";

/** App configuration */
interface Config {
  /** Firebase config */
  firebase: FirebaseConfig;
  /** Whether deployed to a production environment */
  production: boolean;
  /** App version */
  version: string;
  /** Git commit hash from build */
  versionHash: string | null;
}

/** Firebase config */
interface FirebaseConfig {
  /** Project API key */
  apiKey: string;
  /** App ID */
  appId: string;
  /** Project auth domain */
  authDomain: string;
  /** Messaging sender ID */
  messagingSenderId: string;
  /** Project ID */
  projectId: string;
  /** Project storage bucket */
  storageBucket: string;
}

/** App configuration */
const config: Config = {
  firebase: {
    apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
    appId: process.env.VUE_APP_FIREBASE_APP_ID,
    authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
    messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_ID,
    projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  },
  production: process.env.NODE_ENV === "production",
  version,
  versionHash: process.env.VUE_APP_GIT_HASH ?? null,
};

export default config;
