









import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class ProgressIndicator extends Vue {
  /** Progress indicator color */
  @Prop({ default: "primary" })
  color!: string;

  /** Progress indicator size */
  @Prop({ default: 48 })
  size!: number;
}
