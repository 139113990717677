import VueRouter from "vue-router";

// Utilities
import { auth } from "@/firebase";

const guardRouter = (router: VueRouter): void => {
  router.beforeEach(async (to, from, next) => {
    const isAuthenticated = Boolean(auth.currentUser);

    if (!isAuthenticated) {
      // Protect routes that require authentication
      const requiresAuth = to.matched.some((x) => x.meta.requiresAuth);

      if (requiresAuth) {
        return next({ path: "/", query: { redirect: to.fullPath } });
      }
    } else {
      // Redirect the root route to timeline when authenticated
      if (to.path === "/") {
        return next("/timeline");
      }

      // Hide routes that require no authentication
      const requiresNoAuth = to.matched.some((x) => x.meta.requiresNoAuth);
      if (requiresNoAuth) {
        return next("/timeline");
      }
    }

    next();
  });
};

export default guardRouter;
