import { Component, Vue } from "vue-property-decorator";

// Utilities
import { Errors } from "@/errors";
import { loginGoogle, logoutGoogle } from "@/firebase";
import { UserService } from "@services";
import { UserModule } from "@store/modules";
import { getUrlRedirect } from "@router";
import { User } from "@typings/user";

/**
 * Common authentication helpers
 */
@Component
export default class AuthMixin extends Vue {
  /** Whether the user is authenticated */
  get authenticated(): boolean {
    return UserModule.authenticated;
  }

  /** Whether the user is authenticating */
  get authenticating(): boolean {
    return UserModule.authenticating;
  }

  /** Authenticated user */
  get user(): User | null {
    return UserModule.user;
  }

  /**
   * Log the user in via Google and get profile information
   *
   * @param   redirect Optional redirect after authentication (defers to URL redirect)
   * @returns Authenticated user profile
   */
  async login(redirect?: string): Promise<void> {
    // Prevent authenticating twice
    if (UserModule.authenticating) return;
    UserModule.setAuthenticating(true);

    try {
      // NOTE: Authenticating with Google can throw "cancelled" error if user closes popup
      const firebaseUser = await loginGoogle();
      await UserService.getUser(firebaseUser);

      // URL may contain a redirect route after authentication but defaults to Timeline.
      //   App must be reloaded in order to properly fetch initial data, etc.
      // TODO: Investigate using a global event to fetch initial data in 'App' component?
      const urlRedirect = getUrlRedirect();
      if (urlRedirect) {
        location.replace(urlRedirect);
      } else if (redirect) {
        location.replace(redirect);
      } else {
        location.reload();
      }
    } catch (e) {
      await this.resetAuth();

      // Closing the Google login modal should not throw an error
      if (e.message === Errors.AUTH__CANCELLED) return;
      // eslint-disable-next-line no-console
      console.error("Authentication error", e.message);

      const errorMessage =
        e.message === Errors.AUTH__NO_PROFILE
          ? "User is not authorized for Journal"
          : "Error authenticating with Google";
      this.$notifyError(errorMessage);
    }
  }

  /**
   * Cleanup all authentication references
   */
  async resetAuth(): Promise<void> {
    await logoutGoogle();

    UserModule.removeUser();
    UserModule.setAuthenticating(false);
  }
}
