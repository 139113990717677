















import { Component, Mixins } from "vue-property-decorator";

// Components
import {
  TheAppHeader,
  TheAppSnackbar,
  TheDebugHelper,
} from "@components/single";

// Utilities
import { auth } from "@/firebase";
import { AuthMixin } from "@mixins";
import { UserService } from "@services";
import { EntryModule, UserModule } from "@store/modules";
import { handleUrlRedirect, protectRoute } from "@router";

@Component({
  components: {
    TheAppHeader,
    TheAppSnackbar,
    TheDebugHelper,
  },
})
export default class App extends Mixins(AuthMixin) {
  fetchingData = false;

  mounted(): void {
    this.getInitialData();

    // TODO: Investigate listening to auth.onAuthStateChanged() to clean up users
    //         when signed out (unsure if necessary).
  }

  /**
   * Get initial app data
   */
  async getInitialData(): Promise<void> {
    this.fetchingData = true;

    try {
      await this.getInitialUser();
      if (!this.authenticated || !this.user) {
        this.fetchingData = false;
        return;
      }

      await EntryModule.getEntries(this.user.uid);

      this.fetchingData = false;
    } catch (e) {
      this.fetchingData = false;
      // TODO: Refactor to use above errors
      this.$notifyError("Could not fetch initial data");
    }
  }

  /**
   * Get the current user from firebase authentication
   */
  async getInitialUser(): Promise<void> {
    const { currentUser: firebaseUser } = auth;

    // Ensure current route is valid for unauthenticated user
    if (!firebaseUser) {
      protectRoute();
      return;
    }

    try {
      UserModule.setAuthenticating(true);
      const profile = await UserService.getUser(firebaseUser);
      UserModule.setAuthenticating(false);
      UserModule.setUser(profile);

      // URL may contain a redirect route after authentication
      handleUrlRedirect();
    } catch (e) {
      await this.resetAuth();

      throw new Error("Could not authenticate with Google");
    }
  }
}
