import Vue, { VNode } from "vue";
import ShortkeyPlugin from "vue-shortkey";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/300.css";

// Components
import App from "./App.vue";

// Utilities
import { auth } from "@/firebase";
import ComponentsPlugin from "@plugins/components";
import FiltersPlugin from "@plugins/filters";
import SnackbarPlugin from "@plugins/snackbar";
import VuetifyPlugin from "@plugins/vuetify";
import store from "@store";
import "./registerServiceWorker";
import router from "./router";

Vue.config.productionTip = false;

Vue.use(ComponentsPlugin);
Vue.use(FiltersPlugin);
Vue.use(SnackbarPlugin);
Vue.use(ShortkeyPlugin);

// TODO: Clean up initial page flash by rendering app immediately and awaiting
//         authentication in the router and displaying a loading indicator.

// Only load app once Firebase auth is initialized
let app: Vue | null = null;
auth.onAuthStateChanged(() => {
  if (app) return;

  app = new Vue({
    router,
    store,
    vuetify: VuetifyPlugin,
    render: (h): VNode => h(App),
  }).$mount("#app");
});
