import { VueConstructor } from "vue/types/vue";
import dayjs from "dayjs";

/**
 * Format a Date with DayJS
 *
 * @param   value  - Input date
 * @param   format - Date string format
 * @returns Formatted date string
 */
const formatDate = (value: Date, format: string): string => {
  return dayjs(value).format(format);
};

const FiltersPlugin = {
  install: (Vue: VueConstructor): void => {
    Vue.filter("dayjs", formatDate);
  },
};

export default FiltersPlugin;
