import sort from "fast-sort";
import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule,
} from "vuex-module-decorators";

// Utilities
import config from "@/config";
import store from "@store";
import { EntryService } from "@services";

// Types
import { Entry } from "@typings/entry";

const moduleName = "entry";
// @ts-ignore
if (!config.production && store.state[moduleName]) {
  store.unregisterModule(moduleName);
}

/**
 * Sort journal entries (in-place)
 *
 * @param entries Journal entries
 */
const sortEntries = (entries: Entry[]): void => {
  sort(entries).desc((e): Date => e.date);
};

@Module({ dynamic: true, name: moduleName, namespaced: true, store })
class EntryState extends VuexModule {
  /** Journal entries */
  entries: Entry[] = [];
  /** Whether entries are loading */
  loading = false;

  /** Add a new journal entry */
  @Mutation
  addEntry(entry: Entry): void {
    this.entries.unshift(entry);

    sortEntries(this.entries);
  }

  /** Delete a journal entry */
  @Mutation
  deleteEntry(entry: Entry): void {
    const entryIdx = this.entries.findIndex((e) => e.id === entry.id);
    if (entryIdx < 0) return;

    this.entries.splice(entryIdx, 1);
  }

  /** Initialize state */
  @Mutation
  initialize(): void {
    this.entries = [];
    this.loading = false;
  }

  /** Set journal entries after fetching */
  @Mutation
  setEntries(entries: Entry[]): void {
    this.entries = entries;
    this.loading = false;

    sortEntries(this.entries);
  }

  /** Indicate loading the journal entries */
  @Mutation
  setLoading(loading = true): void {
    this.loading = loading;
  }

  /** Update a journal entry */
  @Mutation
  updateEntry(entry: Entry): void {
    const entryIdx = this.entries.findIndex((e) => e.id === entry.id);
    if (entryIdx < 0) return;

    this.entries[entryIdx] = entry;
    sortEntries(this.entries);
  }

  /** Get the journal entries */
  @Action
  async getEntries(userId: string): Promise<Entry[]> {
    this.setLoading();

    const entries = await EntryService.getEntries(userId);

    this.setEntries(entries);
    this.setLoading(false);

    return entries;
  }
}

export { EntryState };
export default getModule(EntryState);
