
































import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import { mdiDelete, mdiPencil, mdiStar } from "@mdi/js";
import dayjs from "dayjs";

// Utilities
import { moodIcons } from "@components/editor/helpers";

// Types
import { EditorIconSelectorIcon } from "@typings/editor";
import { Entry } from "@typings/entry";

@Component
export default class JournalEntry extends Vue {
  /** Journal entry */
  @Prop({ required: true })
  entry!: Entry;

  icons = { mdiDelete, mdiPencil, mdiStar };

  /** Journal entry date (readable) */
  get date(): string {
    return dayjs(this.entry.date).format("MMMM D, YYYY");
  }

  /** Journal entry mood icon */
  get moodIcon(): EditorIconSelectorIcon | null {
    const { mood } = this.entry;
    if (!mood) return null;

    return moodIcons[mood] ?? null;
  }

  /**
   * Delete a journal entry
   *
   * @returns Journal entry to delete
   */
  @Emit("delete")
  deletePrompt(): Entry {
    return this.entry;
  }

  /**
   * Update a journal entry
   * @param   entry - Journal entry to update
   *
   * @returns Journal entry to update
   */
  @Emit("update")
  update(): Entry {
    return this.entry;
  }
}
