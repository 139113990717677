import colors from "vuetify/lib/util/colors";
import {
  mdiEmoticonAngry,
  mdiEmoticonCry,
  mdiEmoticonExcited,
  mdiEmoticonHappy,
  mdiEmoticonNeutral,
} from "@mdi/js";

// Types
import { Moods } from "@typings/enums";
import { EditorIconSelectorIcon } from "@typings/editor";

/** Mood icon map */
const moodIcons: Record<Moods, EditorIconSelectorIcon> = {
  [Moods.UPSET]: {
    color: colors.red.darken2,
    icon: mdiEmoticonAngry,
    value: Moods.UPSET,
  },
  [Moods.SAD]: {
    color: colors.orange.darken2,
    icon: mdiEmoticonCry,
    value: Moods.SAD,
  },
  [Moods.CONTENT]: {
    color: colors.yellow.darken2,
    icon: mdiEmoticonNeutral,
    value: Moods.CONTENT,
  },
  [Moods.HAPPY]: {
    color: colors.lightGreen.darken2,
    icon: mdiEmoticonHappy,
    value: Moods.HAPPY,
  },
  [Moods.EXCITED]: {
    color: colors.green.darken2,
    icon: mdiEmoticonExcited,
    value: Moods.EXCITED,
  },
};

/** Mood icon list */
const moodIconList: EditorIconSelectorIcon[] = Object.values(moodIcons);

export { moodIcons, moodIconList };
