import {
  getModule,
  Module,
  Mutation,
  VuexModule,
} from "vuex-module-decorators";

// Utilities
import config from "@/config";
import store from "@store";
import { User } from "@typings/user";

const moduleName = "user";
// @ts-ignore
if (!config.production && store.state[moduleName]) {
  store.unregisterModule(moduleName);
}

@Module({ dynamic: true, name: moduleName, namespaced: true, store })
class UserState extends VuexModule {
  /** Whether user is authenticating */
  authenticating = false;
  /** User profile */
  user: User | null = null;

  /** Whether user is authenticated */
  get authenticated(): boolean {
    return Boolean(this.user);
  }

  /** Increment user entries count */
  @Mutation
  addEntry(): void {
    if (!this.user) return;
    this.user.entries++;
  }

  /** Decrease user entries count */
  @Mutation
  deleteEntry(): void {
    if (!this.user) return;
    this.user.entries--;
  }

  /** Initialize state */
  @Mutation
  initialize(): void {
    this.authenticating = false;
    this.user = null;
  }

  /** Set whether the user is authenticating */
  @Mutation
  setAuthenticating(authenticating: boolean): void {
    this.authenticating = authenticating;
  }

  /** Set the user profile */
  @Mutation
  setUser(user: User): void {
    this.user = user;
  }

  /** Clear the user profile */
  @Mutation
  removeUser(): void {
    this.user = null;
  }
}

export { UserState };
export default getModule(UserState);
