// Utilities
import { MEDIA_QUERY_VISIBILITY_KEY } from "@/constants";

abstract class Storage {
  /**
   * Get a boolean from localStorage
   *
   * @param   key Local storage key
   * @returns Boolean value
   */
  static getBoolean(key: string): boolean {
    const booleanValue = localStorage.getItem(key);
    if (!booleanValue) return false;

    try {
      return JSON.parse(booleanValue);
    } catch {
      return false;
    }
  }

  /**
   * Determin whether media query indicator is visible
   *
   * @returns Whether media query indicator is visible
   */
  static getMediaQueryVisibility(): boolean {
    return this.getBoolean(MEDIA_QUERY_VISIBILITY_KEY);
  }

  /**
   * Toggle whether the media query indicator is visible
   *
   * @param visible Whether media query indicator is visible
   */
  static setMediaQueryVisibility(visible: boolean): void {
    localStorage.setItem(MEDIA_QUERY_VISIBILITY_KEY, JSON.stringify(visible));
  }
}

export default Storage;
